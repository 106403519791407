if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}

const header = document.querySelector('#header');

function scrollIt(element) {
  window.scrollTo({
    'behavior': 'smooth',
    'left': 0,
    'top': element.offsetTop - header.offsetHeight
  });
}

document.addEventListener("DOMContentLoaded", function() {

  // Set css variable --header-height
  var root = document.documentElement;
  root.style.setProperty('--header-height', header.offsetHeight + "px");
  
  // document.querySelector('#appointment-button .button').addEventListener('click', function (event) {

  //   var anchor = document.querySelector('#schedule-appointment');

  //   if ( !anchor ) return;

  //   event.preventDefault();
    
  //   scrollIt(anchor);

  // }, false);

  const menuToggle = document.querySelector('.menu-toggle');

  menuToggle.onclick = function() {

    document.querySelector('body').classList.toggle('menu-open');
    
  }

  objectFitImages();
});